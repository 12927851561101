import { ReactElement } from 'react'
import type { AppProps } from 'next/app'
import Router from 'next/router'
import NProgress from 'nprogress'

import '../styles/globals.scss'

Router.events.on('routeChangeStart', NProgress.start)
Router.events.on('routeChangeError', NProgress.done)
Router.events.on('routeChangeComplete', NProgress.done)

function MyApp({ Component, pageProps }: AppProps): ReactElement {
  return <Component {...pageProps} />
}

export default MyApp
